
  import { defineComponent, ref, onMounted, computed } from "vue";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  // import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
  import AddTagModal from "@/components/modals/forms/AddTagModal.vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { Actions } from '@/store/enums/StoreEnums' 
  import { useStore } from "vuex";
  export default defineComponent({
    name: "language-listing",
    components: {
      Datatable,
      AddTagModal,
      // ExportCustomerModal,
      // AddCustomerModal,
    },
    setup() {
      const store = useStore()
      const checkedCustomers = ref([]);
      const tableHeader = ref([
        // {
        //   key: "checkbox",
        //   sortable: false,
        // },
        {
          name: "Nomi",
          key: "name",
          sortable: true,
        },
        {
          name: "View",
          key: "view",
          sortable: true,
        },
        {
          name: "Status",
          key: "status",
          sortable: true,
        },
        {
          name: "",
          key: "actions",
        },
      ]);
  

      const dataTable = computed(()=>{
        return store.getters.alTags
      })

  
      onMounted(async() => {
        setCurrentPageBreadcrumbs("Teglar", ["Bo'limlar"]);
        await store.dispatch(Actions.ALL_TAGS, {params:{limit: 10}});
      });

      const count = computed(()=>{
            return store.getters.getCount
        })

      const changeCurrent = async(current: number)=>{ 
        await store.dispatch(Actions.ALL_TAGS, {params:{next: current}});
      }


      const perPage = async(val:number)=>{
        await store.dispatch(Actions.ALL_TAGS, {params:{limit: val}});
      }

      const edit = ref<boolean>(false)

      const itemId = ref<string>("")

      const editItem = (item: string)=>{
        edit.value = true;
        itemId.value = item
        
      }
      const changeStatus = async(item: string, status: number)=>{
        await store.dispatch(Actions.CHANGE_TAG_STATUS, {item, status});
      }
  
      const deleteFewCustomers = () => {
        checkedCustomers.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedCustomers.value.length = 0;
      };
  
      const deleteCustomer = async(id:string) => {
        await store.dispatch(Actions.DEL_TAG, id);
      };
  
      const search = ref<string>("");
      const searchItems = async() => {
        console.log('hello');
        
        await store.dispatch(Actions.ALL_TAGS, {params: {title: search.value}});
      };
  
      // const searchingFunc = (obj, value): boolean => {
      //   // for (let key in obj) {
      //   //   if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
      //   //     if (obj[key].indexOf(value) != -1) {
      //   //       return true;
      //   //     }
      //   //   }
      //   // }
      //   // return false;
      // };
      

      return {
        tableHeader,
        deleteCustomer,
        search,
        searchItems,
        checkedCustomers,
        deleteFewCustomers,
        dataTable, edit, editItem, itemId, perPage, changeStatus, count, changeCurrent
      };
    },
  });
  